import React, { Component } from 'react'
import { Jumbotron as Jumbo } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
.hero {
  background-image: url(./assets/img/topbanner.jpg);
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
`;

export default class Home extends Component {
  render() {
    return (
      <Styles>
        <Jumbo fluid className="hero" />
      </Styles>
    )
  }
}