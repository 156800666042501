import React from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

function onChange(value) {
  console.log("Captcha value:", value);
}

export default function Contact() {
  
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('lonn_baker', 'lbcontact', e.target, 'user_nj69BKrcTq1v1OP8C7uqN')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1 className="text-center my-5">Contact Me</h1>
        </Col>
      </Row>

      <Row>
        <Col md={{ span: 8, offset: 2 }}>
        <p>Feel free to contact me to discuss your project. I usually respond within 24 hours.</p>
          <Form className="contact-form" onSubmit={sendEmail}>
            <Form.Group controlId="user_name">
              <Form.Control type="text" placeholder="Name" />
            </Form.Group>

            <Form.Group controlId="user_email">
              <Form.Control type="email" placeholder="Email" />
            </Form.Group>

            <Form.Group controlId="message">
              <Form.Control as="textarea" placeholder="Message" rows="4" />
            </Form.Group>

            <Form.Group>
              <ReCAPTCHA sitekey="6LfN7-kUAAAAABxkTOOasN_uhUl4HwXN1kELOXKu" onChange={onChange}/>
            </Form.Group>

            <Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}