import React, { Component } from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import styled from 'styled-components';

const Title = styled.h3`
	text-align: center;
`;

export default class Work extends Component {
	render() {
		return (
			<Container noGutters>
				<Row>
					<Col>
						<h1 className="text-center my-5">Werk</h1>
					</Col>
				</Row>

				<Row noGutters className="align-items-center">
					<Col xs={12} md={6} className="order-md-2">
						<Image src="./assets/img/joe1.jpg" fluid />
					</Col>
					<Col xs={12} md={6} className="order-md-1">
						<div className="p-3">
							<Title>Joe Solo Productions</Title>
							<p>Joe Solo is an LA based Record Producer and founder of the Music Success Workshop. He worked closely with Lonn to develope his strategies to promote his production company and his ongoing Music Success Workshops</p>

							<Row className="justify-content-center">
								<Button href="http://joesoloproductions.com/" target="_blank" variant="outline-primary">Visit Site
							</Button>
							</Row>
						</div>
					</Col>
				</Row>		

				<Row noGutters className="align-items-center">
				<Col xs={12} md={6} className="order-md-1">
						<Image src="./assets/img/mackie.jpg" fluid />
					</Col>
					<Col xs={12} md={6} className="order-md-2">
						<div className="p-3">
							<Title>Mackie Osborne</Title>
							<p>Mackie is a Graphic Designer and Printmaker in Los Angeles. She had some ideas for her portfolio site and enlisted Lonn to help her bring her vision to life. From multi-layed photoshop files provided by Mackie, Lonn created this fun portfolio site that shows off Mackie's creative designs.</p>

							<Row className="justify-content-center">
								<Button href="http://www.mackieosborne.com/" target="_blank" variant="outline-primary">Visit Site
							</Button>
							</Row>
						</div>
					</Col>
				</Row>				

				<Row noGutters className="align-items-center">
					<Col xs={12} md={6} className="order-md-2">
						<Image src="./assets/img/mcs.jpg" fluid />
					</Col>
					<Col xs={12} md={6} className="order-md-1">
						<div className="p-3">
							<Title>Millenium Computer Services</Title>
							<p>Millenium Computer Services hired Lonn to update their outdated website. SEO Optimization was a top priority. They also needed to take control of their content. The new Modx site has boosted search visability and they are very happy with the results.</p>

							<Row className="justify-content-center">
								<Button href="http://millenniumcs.com/" target="_blank" variant="outline-primary">Visit Site
							</Button>
							</Row>
						</div>
					</Col>
				</Row>
			
				<Row noGutters className="align-items-center">
					<Col xs={12} md={6} className="order-md-1">
						<Image src="./assets/img/techni.jpg" fluid />
					</Col>
					<Col xs={12} md={6} className="order-md-2">
						<div className="p-3">
							<Title>Techni-Mold & Engineering Inc.</Title>
							<p>Techni-Mold Engineering, Inc. is a leader in Plastic Injection Molding , Mold Design, Mold Repair, and manufacture of molded plastic parts in the Los Angeles and Southern California area</p>

							<Row className="justify-content-center">
								<Button href="https://techni-mold.com/" target="_blank" variant="outline-primary">Visit Site
								</Button>
							</Row>
						</div>
					</Col>
				</Row>
			
			</Container>
		)
	}
}