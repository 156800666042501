import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Hero from './Hero';

export default class Home extends Component {
  render() {
    return (
      <>
      <Hero />
      <Container>
        <Row>
          <Col>
          <h1 className="text-center mt-4">Welcome</h1>
          <p>Hi, I am Lonn Baker - a web developer and graphic designer in Los Angeles with 15 years experience. My clients range from the Entertainment Buisiness, Health Care, Tech and Manufacturing. Let's work together to build something great.</p>
          </Col>
        </Row>
      </Container>
      </>
    )
  }
}
