import React from 'react'
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { Navbar, Nav } from 'react-bootstrap'
import Home from './components/Home'
import Werk from './components/Portfolio'
import Contact from './components/Contact'
import './App.scss'

const routes = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/Portfolio', name: 'Werk', Component: Werk },
  { path: '/Contact', name: 'Contact', Component: Contact },
]

function App2() {
  return (
    <Router>
        <Navbar bg="dark" variant="dark" expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              {routes.map(route => (
                <Nav.Link
                  key={route.path}
                  as={NavLink}
                  to={route.path}
                  activeClassName="active"
                  exact
                >
                  {route.name}
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {routes.map(({ path, Component }) => (
          <Route key={path} exact path={path}>
            {({ match }) => (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="page"
                unmountOnExit
              >
                <Component />
              </CSSTransition>
            )}
          </Route>
        ))}
    </Router>
  )
}

export default App2;